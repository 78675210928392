// Import LogRocket and initialize it if we aren't on localhost
// import LogRocket from 'logrocket';
if (window.location.hostname !== 'localhost') {
  // LogRocket.init('dukkbf/beyond-control-panel');
}

import Vue from 'vue'

import VueRouter from 'vue-router'
Vue.use(VueRouter);

// Import Axios and configure a custom Axios instance for v3
import axios from 'axios';
const endpoint = process.env.VUE_APP_ENDPOINT ? process.env.VUE_APP_ENDPOINT + '/v3' : 'https://api.magicconvention.com/v3';

const api3 = axios.create({
  baseURL: endpoint,
})
Object.defineProperty(Vue.prototype, '$api3', { value: api3 });

// Add a custom date helper that uses date-fns-tz
import { dateHelper } from './common/js/date-helper';
Object.defineProperty(Vue.prototype, '$dateHelper', { value: dateHelper });

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

import VueCurrencyFilter from 'vue-currency-filter';
Vue.use(VueCurrencyFilter, {
	symbol: '$',
	thousandsSeparator: ',',
	fractionCount: 2,
	fractionSeparator: '.',
	symbolPosition: 'front',
	symbolSpacing: false
});

import App from './App.vue'


Vue.config.productionTip = false

// Define route components
import Logout from './components/pages/Logout';
import Account from './components/pages/Account';
import Dashboard from './components/pages/Dashboard';
import DealerBooths from './components/pages/DealerBooths';
import Group from './components/pages/Group';
import Groups from './components/pages/Groups';
import HotelReservations from './components/pages/HotelReservations';
import PastDueTransactions from './components/pages/PastDueTransactions';
import Profile from './components/pages/Profile';
import Profiles from './components/pages/Profiles';
import ProfileTags from './components/pages/ProfileTags';
import Registration from './components/pages/Registration';
import Registrations from './components/pages/Registrations';
import RegistrationTags from './components/pages/RegistrationTags';
import Scheduling from './components/pages/Scheduling';
import SearchResults from './components/pages/SearchResults'
import Shows from './components/pages/Shows';
import Toolbox from './components/pages/Toolbox';
import WaitlistGroups from './components/pages/WaitlistGroups';
import WaitlistInvitations from './components/pages/WaitlistInvitations';

// Define routes
const routes = [
	{ path: '/', component: Dashboard },
  { path: '/logout', component: Logout },
	{ path: '/accounts/:id', component: Account },
  { path: '/dealer-booths', component: DealerBooths },
	{ path: '/groups/:id', component: Group },
	{ path: '/groups', component: Groups },
	{ path: '/hotel-reservations', component: HotelReservations },
  { path: '/past-due-transactions', component: PastDueTransactions },
	{ path: '/profiles/tags', component: ProfileTags },
	{ path: '/profiles/:id', component: Profile },
	{ path: '/profiles', component: Profiles },
  { path: '/registrations', component: Registrations},
  { path: '/registrations/tags', component: RegistrationTags},
  { path: '/registrations/:id', component: Registration },
  { path: '/scheduling', component: Scheduling },
  { path: '/search', component: SearchResults },
  { path: '/shows', component: Shows },
  { path: '/toolbox', component: Toolbox },
  { path: '/waitlist-groups', component: WaitlistGroups },
  { path: '/waitlist-invitations', component: WaitlistInvitations },
]

// Construct router
const router = new VueRouter({
	routes,
	mode: 'history'
})

new Vue({
	router,
	render: h => h(App),
}).$mount('#app');
